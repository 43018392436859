<template>
  <div class="main-frame">
    <template v-if="cards.length > 0">
      <v-btn
        @click="showModalCreate"
        height="30"
        width="155"
        outlined
        class="ml-5 mt-7 mb-1 btn-primary rounded-lg text-none fz-13 fw-60">
        + Add Niche
      </v-btn>
      <div class="mt-5 mx-5 d-flex flex-wrap">
        <v-card
          v-for="card in cards"
          :key="card.id"
          max-width="360"
          width="360"
          class="mr-5 mb-5 pa-4 rounded-4 card-flex card-hover pointer hidden"
          :class="{'card-active': card.id === selectedCard.id}"
          outlined
          @click="showModalEditNiches(card)"
        >
          <v-row class="ma-0 mb-2">
            <v-img v-if="card.iconUrl" max-width="32" max-height="32" :src="card.iconUrl"></v-img>
            <v-icon v-else>{{ '$appIcon' }}</v-icon>
            <v-spacer></v-spacer>
            <v-menu
              offset-y
              left
              nudge-right="81"
              nudge-top="5"
              content-class="card-menu elevation-0 px-3 py-3"
              min-width="180"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-sheet @click.stop elevation="0" color="transparent">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    size="24"
                    :ripple="false"
                    class="dots pa-1 card-dots hover-none"
                  >
                    $dots
                  </v-icon>
                </v-sheet>
              </template>
              <v-list class="py-0 px-4 menu-shadow rounded-lg border-lightgray card-menu-triangle">
                <v-list-item class="pa-0 bb-gray">
                  <v-list-item-title
                    class="fz-14 fw-600 d-flex align-center card-menu-item pointer"
                    @click="showModalEditNiches(card); readonly = false"
                  >
                    <span>Edit</span>
                    <v-spacer></v-spacer>
                    <v-icon size="30" style="padding: 3px" class="rounded-circle">$priceEdit</v-icon>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item class="pa-0">
                  <v-dialog
                    transition="dialog-bottom-transition"
                    max-width="370"
                    content-class="rounded-xl"
                    overlay-color="rgba(9, 24, 73, 0.2)"
                    overlay-opacity="1"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item-title
                        class="fz-14 fw-600 d-flex align-center card-menu-item pointer"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <span>Delete</span>
                        <v-spacer></v-spacer>
                        <v-icon size="30" style="padding: 5px" class="rounded-circle">$delete</v-icon>
                      </v-list-item-title>
                    </template>
                    <template v-slot:default="dialog">
                      <v-card class="pa-7">
                        <v-card-text class="py-0">
                          <div class="fz-18 text-center text-black">Do you really want to delete {{ card.name }}?</div>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions class="justify-space-between pa-0">
                          <v-btn
                            class="rounded-lg text-none fz-16 fw-600"
                            outlined
                            width="150"
                            height="40"
                            @click="dialog.value = false"
                          >Return
                          </v-btn>
                          <v-btn
                            elevation="0"
                            color="#FF0000"
                            width="150"
                            height="40"
                            class="rounded-lg white--text text-none fz-16 fw-600"
                            @click="deleteNiches(card.id)"
                          >
                            <v-icon left class="stroke-white">
                              $delete
                            </v-icon>
                            Delete
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-row>
          <p class="fz-14 lh-22 mb-0 card-title font-weight-bold text-black"> {{ card.name }}</p>
          <v-row class="ma-0 justify-space-between flex-nowrap">
          <span class="fz-14 pt-0 mr-2 font-weight-light mt-2 text-gray text-no-wrap">
            Quantity: {{ card.ordersCount }}
          </span>
          </v-row>
        </v-card>
      </div>
    </template>
    <template v-else>
      <v-row justify="center" align="center" align-content="center" class="height-100">
        <p class="text-center mt-6 v-toolbar__title ml-4 title-offset fz-24 text-black fw-800 width-100">Niches are empty, create it</p>
        <v-btn
          @click="showModalCreate"
          height="30"
          width="155"
          outlined
          class="btn-primary rounded-lg text-none fz-13 fw-60">
          + Add Niche
        </v-btn>
      </v-row>
    </template>
    <!--Modal Edit Niches-->
    <v-navigation-drawer
      v-model="isShowModalEditNiches"
      fixed
      right
      bottom
      width="380"
      id="niches-info"
      class="niches-info"
    >
      <v-col class="pa-0">
        <!--Header-->
        <v-row
          class="bb-gray px-7 py-3 ma-0 flex-grow-0 d-flex align-center justify-space-between"
          style="max-height: 70px; min-height: 70px"
        >
          <span class="fz-16 font-weight-bold text-black" style="margin-left: 2px">
            {{ selectedCard.name ?  selectedCard.name : 'Add Niche'}}
          </span>
          <v-icon class="close-icon" @click="closeModalEditNiches">$close</v-icon>
        </v-row>
        <!--Icon-->
        <v-col class="d-flex align-center flex-column mb-2">
          <div v-if="!readonly" class="d-flex justify-center flex-column align-center">
            <v-subheader class="px-0">Icon</v-subheader>
            <v-avatar
              size="100"
              @click="$refs.uploadIcon.$refs.input.click()"
              class="designer-avatar-empty mb-2 pointer"
            >
              <img v-if="newCard.imgUrl" :src="newCard.imgUrl" alt="NichesIcon">
              <img v-else-if="selectedCard.iconUrl && !readonly" :src="selectedCard.iconUrl" alt="NichesIcon">
              <v-icon v-else size="32">$companyLogo</v-icon>
            </v-avatar>
            <v-file-input
              v-model="newCard.choosenIcon"
              @change="showSelectedImg"
              ref="uploadIcon"
              accept=".svg"
              type="file"
              style="display: none"
            />
            <v-btn
              @click="$refs.uploadIcon.$refs.input.click()"
              elevation="0"
              color="primary"
              class="text-none text-caption font-weight-bold"
            >
              + Add icon
            </v-btn>
          </div>
          <div v-else class="d-flex justify-center flex-column align-center">
            <v-avatar
              v-if="selectedCard.iconUrl"
              size="100"
              @click="$refs.uploadIcon.$refs.input.click()"
              class="mb-2 pointer"
              tile
            >
              <img :src="selectedCard.iconUrl" alt="NichesIcon">
            </v-avatar>
          </div>
        </v-col>
        <!--Form-->
        <v-col class="px-7 py-3">
          <v-form v-model="valid" ref="from" class="mb-2" @submit.prevent>
            <div>
              <p class="fz-14 mb-1 text-gray">
                Niches Name
              </p>
              <v-text-field
                v-model="newCard.name"
                dense
                outlined
                class="rounded-lg input-border text-black fz-14 font-weight-medium"
                :disabled="readonly"
                :rules="[rules.required]"
              />
            </div>
          </v-form>
          <v-row class="ma-0 flex-grow-0 justify-space-between" style="padding: 30px 0 !important;">
            <v-btn @click="closeModalEditNiches" elevation="0" color="primary" height="40" width="155" class=" rounded-lg fz-16 fw-600 text-none" outlined>Cancel</v-btn>
            <v-btn
              v-if="!readonly"
              @click="mode === 'save' ? createNiches() : updateNiches()"
              :disabled="!valid"
              elevation="0"
              color="primary"
              height="40"
              width="155"
              class="rounded-lg fz-16 fw-600 text-none">
              Save
            </v-btn>
            <v-btn v-if="readonly" @click="EditNiches" elevation="0" color="primary" height="40" width="155" class="rounded-lg fz-16 fw-600 text-none">Edit</v-btn>
          </v-row>
        </v-col>
      </v-col>
    </v-navigation-drawer>
    <!--SnackBar-->
    <v-snackbars
      :messages.sync="messages"
      color="red"
      timeout="2500"
      multi-line
      max-width="350"
      left
      rounded="lg"
      light
      elevation="4"
      content-class="snackbar"
    >
      <template v-slot:default="{ message }">
        <strong>{{ message.title }}</strong>
        <p>{{ message.text }}</p>
        <v-row v-if="message.title === 'Error'" class="ma-0 justify-end">
          <v-btn
            class="rounded-lg fz-12 fw-600 text-none border-none"
            outlined
            height="26"
            elevation="0"
            @click="doCopy('Error', message.text)"
          >Copy</v-btn>
        </v-row>
      </template>
    </v-snackbars>
    <!--Loader-->
    <div class="preloader" v-if="loading">
      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import VSnackbars from 'v-snackbars'
import axios from 'axios'

export default {
  name: 'NichesViews',
  components: { 'v-snackbars': VSnackbars },
  data() {
    return {
      mode: 'save',
      valid: true,
      cards: [],
      isShowModalEditNiches: false,
      cardId: null,
      readonly: true,
      rules: {
        required: value => (value !== null && String(value).length > 0) || 'Required.'
      },
      newCard: {
        name: '',
        imgUrl: null
      },
      selectedCard: [],
      messages: [],
      loading: false
    }
  },
  methods: {
    async getAllNiches() {
      this.loading = true
      await this.$apollo.query({
        query: gql`query getNichesItem {
            orders {
              niche {
                data {
                  id
                  iconUrl
                  name
                  ordersCount
                }
              }
            }
          }`,
        fetchPolicy: 'no-cache'
      }).then(data => {
        this.loading = false
        this.cards = data.data.orders.niche.data
      })
    },
    async createNiches() {
      if (!this.$refs.from.validate()) return
      this.loading = true
      await this.$apollo.mutate({
        mutation: gql`mutation createOrder($input: CreateNicheInput!) {
            orders {
              createNiche(input: $input) {
                id
                name
                iconUploadUrl
              }
            }
          }`,
        variables: {
          input: {
            name: this.newCard.name
          }
        }
      }).then((data) => {
        const item = data.data.orders.createNiche
        if (this.newCard.imgUrl) {
          const formData = new FormData()
          formData.append('file', this.newCard.choosenIcon)
          axios.post(item.iconUploadUrl, formData)
            .then(resp => {
              this.getAllNiches()
              this.closeModalEditNiches()
            })
            .catch(error => {
              console.log(error)
            })
        } else {
          this.getAllNiches()
          this.closeModalEditNiches()
        }
        this.loading = false
        this.addSnack('Success added', 'Niche added')
        this.getAllNiches()
      }).catch(err => {
        this.loading = false
        console.log(err)
        this.addSnack('item.name', 'Error', err)
      })
    },
    async updateNiches() {
      if (!this.$refs.from.validate()) return
      this.loading = true
      await this.$apollo.mutate({
        mutation: gql`mutation updateNiche($input: UpdateNicheInput!) {
          orders {
            updateNiche(input: $input) {
              id
              name
              iconUploadUrl
            }
          }
        }`,
        variables: {
          input: {
            id: this.selectedCard.id,
            name: this.newCard.name
          }
        }
      }).then((data) => {
        const item = data.data.orders.updateNiche
        if (this.newCard.imgUrl) {
          const formData = new FormData()
          formData.append('file', this.newCard.choosenIcon)
          axios.post(item.iconUploadUrl, formData)
            .then(resp => {
              this.getAllNiches()
              this.closeModalEditNiches()
            })
            .catch(error => {
              console.log(error)
            })
        } else {
          this.getAllNiches()
          this.closeModalEditNiches()
        }
        this.loading = false
        this.readonly = true
        this.addSnack('Changing was succeed', 'Changing was succeed')
        this.getAllNiches()
      }).catch(err => {
        this.loading = false
        this.addSnack('item.name', 'Error', err)
      })
    },
    async deleteNiches(id) {
      this.loading = true
      await this.$apollo.mutate({
        mutation: gql`mutation deleteNiche($id: ID!) {
            orders {
              deleteNiche(id: $id)
            }
          }`,
        variables: {
          id: id
        }
      }).then(() => {
        this.loading = false
        this.addSnack('Success delete', 'Niche deleted')
        this.getAllNiches()
      })
    },
    showModalCreate(item) {
      this.mode = 'save'
      this.newCard.name = ''
      this.selectedCard = []
      this.readonly = false
      this.isShowModalEditNiches = true
    },
    showModalEditNiches(item) {
      this.mode = 'edit'
      this.readonly = true
      this.selectedCard = item
      this.newCard.name = item.name
      this.isShowModalEditNiches = true
    },
    closeModalEditNiches() {
      this.newCard = {
        name: '',
        imgUrl: null
      }
      this.readonly = true
      this.isShowModalEditNiches = false
    },
    EditNiches() {
      this.readonly = false
    },
    addSnack (item, result, text) {
      this.messages.push({ title: result, text: text })
    },
    showSelectedImg() {
      this.newCard.imgUrl = URL.createObjectURL(this.newCard.choosenIcon)
    }
  },
  computed: {},
  created() {
    this.getAllNiches()
  }

}
</script>

<style lang="scss">
#niches-info {
  height: calc(100% - 72px) !important;
  margin-top: 80px;
}
.main-frame {
  height: calc(100% - 72px) !important;
}
</style>
